import React, { useCallback, useState, useEffect } from 'react';
import Loader from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import IndicatorCard from '../DiagnosticoIndicadores/components/IndicatorCard';
import Graphic from '../DiagnosticoIndicadores/components/Graphic';
import ExternalLayout from '../../../components/ExternalLayout';
import { Container, DetailHeaderContainer, GraphicContainer } from './styles';

function DiagnosticIndicatorDetail() {
  const { ibgeCode, indicatorId } = useParams();
  const [loading, setLoading] = useState(true);
  const [diagnostico, setDiagnostico] = useState();
  const [indicador, setIndicador] = useState();
  const [detailIndicador, setDetailIndicador] = useState();

  const getData = useCallback(async () => {
    try {
      const locationResponse = await api.get(
        `/v1/diagnostico/${ibgeCode}/`,
      );
      console.log(locationResponse);
      setDiagnostico(locationResponse.data.location);

      const indicatorResponse = await api.get(
        `/v1/locations/${locationResponse.data.location.ibge_code}/indicators/`,
      );

      const filteredIndicator = indicatorResponse.data.indicators.filter(
        ind => ind.indicator.id === Number(indicatorId),
      )[0];

      setIndicador(filteredIndicator);

      const detailIndicatorResponse = await api.get(`/${filteredIndicator.detail}`);
      setDetailIndicador(detailIndicatorResponse.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [ibgeCode, indicatorId]);

  useEffect(() => {
    getData();
  }, [getData]);

  console.log({
    detailIndicador
  })

  return (
    <ExternalLayout>
      <Container>
        <div className="content">
          {loading ? (
            <Loader />
          ) : (
            <>
              <h1 className="stateTitle">{diagnostico.name}</h1>
              {/* <Link
                to="/metodologia/indicador-sintetico"
                className="redirectLink"
                style={{ marginBottom: '10px' }}
              >
                Saiba mais sobre os indicadores selecionados
              </Link> */}

              <DetailHeaderContainer>
                <div className="cardContainer">
                  <IndicatorCard indicator={indicador} showButton={false} />
                </div>
                <div className="titleContainer">
                  <span>Indicador</span>
                  <h1>{detailIndicador.complete_name}</h1>
                </div>
              </DetailHeaderContainer>
              <GraphicContainer>
                {detailIndicador.chart && (
                  <Graphic
                    title={detailIndicador.chart_title}
                    subtitle={detailIndicador.chart_subtitle}
                    description={detailIndicador.description}
                    data={detailIndicador.chart}
                    type={detailIndicador.chart_type}
                    calculation_formula={detailIndicador.calculation_formula}
                    content={detailIndicador.content}
                  />
                )}
                {detailIndicador.secundary_chart && (
                  <Graphic
                    title={detailIndicador.secundary_chart_title}
                    subtitle={detailIndicador.secundary_chart_subtitle}
                    description=""
                    data={detailIndicador.secundary_chart}
                    type={detailIndicador.secundary_chart_type}
                    calculation_formula={detailIndicador.calculation_formula}
                  />
                )}
              </GraphicContainer>
            </>
          )}
        </div>
      </Container>
    </ExternalLayout>
  );
}

export default DiagnosticIndicatorDetail;
