import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const LineChart = ({
  data,
  options
}) => {
  useEffect(() => {
    const root = am5.Root.new("chartdivline");

    root.locale = am5locales_pt_BR;

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      
    }));

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 0 });

    xRenderer.grid.template.setAll({
      location: 0,
    })

    xRenderer.labels.template.setAll({
      rotation: -65,
      centerY: am5.p50,
      centerX: am5.p100
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        paddingTop: 20,
        paddingBottom: 20,
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    console.log(data)

    const labels = data.labels;

    const dataExport = data.datasets.map(d => ({
      ...d.data.reduce((acc, item, index) => {
        const label = labels[index].toString();
        acc[label] = item;
        return acc;
      }, {}),
      label: d.label,
    })).reverse();


    console.log(dataExport)

    var exporting = am5plugins_exporting.Exporting.new(root, {
      dataSource: dataExport,
      menu: am5plugins_exporting.ExportingMenu.new(root, {

      }),
      dataFieldsOrder: ["label",],
      pdfOptions: {
        pageSize: "A4",
        pageOrientation: "landscape",
        pageMargins: [20, 20, 20, 40]
      },
      jsonOptions:{
        addColumnNames: true
      },
      csvOptions: {
        addColumnNames: true
      }
    });

    const exportButton = document.getElementsByClassName("am5exporting-icon")[0];
    exportButton.innerHTML = '';
    console.log(exportButton)
    

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      paddingRight: 20,
      renderer: am5xy.AxisRendererY.new(root, {
       
      })
    }));

    const cursor = chart.set("cursor",
    am5xy.XYCursor.new(root, {
      behavior: "none",
    }));
    cursor.lineY.set("visible", true);

    cursor.setAll({
      xAxis: xAxis,
      yAxis: yAxis
    });

    data.datasets.forEach((dataset, index) => {

      let hh = []

      data.labels.forEach((label, index) => {
        hh.push({
          year: label,
          value: dataset?.data[index]
        })
      })

      xAxis.data.setAll(hh); 
      yAxis.data.setAll(hh); 

      const series = chart.series.push(am5xy.LineSeries.new(root, {
        name: dataset.label,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "year",
        fill: dataset?.backgroundColor,
        stroke: dataset?.borderColor
      }));

      let tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        labelText: "{categoryX}: {valueY}",
      });
      
      tooltip.get("background").setAll({
        fill: am5.color(dataset?.backgroundColor),
        fillOpacity: 1
      });
      
      series.set("tooltip", tooltip);

      series.strokes.template.setAll({
        strokeWidth: 5,
      });

      series.bullets.push(function () {

        const circle = am5.Circle.new(root, {
          radius: 6,
          stroke: series.get('fill'),
          strokeWidth: 4,
          fill: root.interfaceColors.get("background"),
        });
        
        return am5.Bullet.new(root, {
          sprite: circle
        });

      });

      series.data.setAll(hh);

    })

    let legend = chart.children.push(am5.Legend.new(root, {
      y: am5.percent(98.8),
      centerX: am5.percent(50),
      x: am5.percent(50),
      scale: 0.75,
    }));
    legend.markers.template.setAll({
      width: 50,
      height: 20
    });
    legend.data.setAll(chart.series.values);
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div>
      <div id="exportdiv"></div>
      <div id="chartdivline" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default LineChart;